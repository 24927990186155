
import React, {
    FunctionComponent,
    Children,
    cloneElement,
    isValidElement,
    ReactElement, useState, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { shallowEqual } from 'recompose'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { useInput, useTranslate, InputProps } from 'ra-core'
import S3Upload from './S3Upload.js'
import FileInputPreview from './FileInputPreview'
import sanitizeRestProps from '../../sanitizeRestProps'
import { InputHelperText, Labeled } from 'ra-ui-materialui'
import Cookies from 'js-cookie'

const useStyles = makeStyles(
    theme => ({
      dropZone: {
        background: theme.palette.background.default,
        cursor: 'pointer',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.getContrastText(
                theme.palette.background.default,
            ),
      },
      previews: {
        display: 'flex'
      },
      preview: {},
      removeButton: {},
      root: { width: '100%' },
    }),
    { name: 'RaFileInput' },
)

export interface FileInputProps {
  accept?: string
  labelMultiple?: string
  labelSingle?: string
  maxSize?: number
  minSize?: number
  multiple?: boolean
}

export interface FileInputOptions extends DropzoneOptions {
  inputProps?: any
  onRemove?: Function
}

export const FileInput: FunctionComponent<
    FileInputProps & InputProps<FileInputOptions>
    > = props => {
      const {
        accept,
        children,
        className,
        classes: classesOverride,
        format,
        helperText,
        label,
        labelMultiple = 'ra.input.file.upload_several',
        labelSingle = 'ra.input.file.upload_single',
        maxSize,
        minSize,
        multiple = false,
        uploadOptions,
        options: {
            inputProps: inputPropsOptions,
            ...options
        } = {} as FileInputOptions,
        parse,
        placeholder,
        resource,
        source,
        validate,
        ...rest
    } = props
      const translate = useTranslate()
      const classes = useStyles(props)

    // turn a browser dropped file structure into expected structure
      const transformFile = file => {
        if (!(file instanceof File)) {
          return file
        }

        const { source, title } = (Children.only(children) as ReactElement<
            any
            >).props

        const preview = URL.createObjectURL(file)
        const transformedFile = {
          rawFile: file,
          [source]: preview,
        }

        if (title) {
          transformedFile[title] = file.name
        }

        return transformedFile
      }

      const transformFiles = (files: any[]) => {
        if (!files) {
          return multiple ? [] : null
        }

        if (Array.isArray(files)) {
          return files.map(transformFile);
        }

        return transformFile(files)
      }

      const {
        id,
        input: { onChange, value, ...inputProps },
        meta,
        isRequired,
    } = useInput({
      format: format || transformFiles,
      parse: parse || transformFiles,
      source,
      type: 'file',
      validate,
      ...rest,
    })
      const { touched, error } = meta

      const files = value ? (Array.isArray(value) ? value.map((file) => file?.path || file) : [value]) : []

      const [fileProgress, setFileProgress] = useState({})
      const onFileProgress = (percentage, status, rawFile) => {
        console.log('onFile progress', percentage)
        const currentProgress = {}
        currentProgress[rawFile.path] = percentage
        setFileProgress({ ...fileProgress, ...currentProgress })

        return
        /*  if (percentage === 0) {
              setLoading(true)
          }
          if (percentage === 100) {
              setLoading(false)
          }
          setPercentage(percentage);*/
      }

      const onDrop = (newFiles, rejectedFiles, event) => {
        console.log('OnDrop', files)
        const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

        if (multiple) {
          onChange(updatedFiles)
        } else {
          onChange(updatedFiles[0])
        }
        const token = Cookies.get('auth-token')

        console.log('OnDrop', files)
        const options = {
          evaporateOptions: {
            bucket: 'ddd',
            aws_key: 'sdsd'

          },

          files: newFiles,
          signingUrlMethod: 'GET',
          accept: '*/*',
          uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
          signingUrlHeaders: {'Authorization': `Bearer ${token}`},
          signingUrlWithCredentials: false,
          signingUrlQueryParams: { uploadType: 'avatar' },
          autoUpload: true,
          onFinishS3Put: onFinishFileUpload,
          onProgress: onFileProgress,
          onError: onFileUploadError,
          signingUrl: `${process.env.REACT_APP_API_URL || ''}/api/media/sign`,
       //   signingUrl: `${process.env.REACT_APP_API_URL || ''}/api/s3/sign`,
          s3path: 'clubtasker/files',
          ...uploadOptions,
        }

        setTimeout(() => {

          new S3Upload(options)
        }, 3000)


        // eslint-disable-line

        if (options.onDrop) {
          options.onDrop(newFiles, rejectedFiles, event)
        }
      }

      const onFileUploadError = (error) => {
        console.error('onFileUploadError', error)
      }
      const onRemove = file => () => {
        if (multiple) {
          const filteredFiles = files.filter(
                stateFile => !shallowEqual(stateFile, file),
            )
          onChange(filteredFiles as any)
        } else {
          onChange(null)
        }

        if (options.onRemove) {
          options.onRemove(file)
        }
      }
      const onFinishFileUpload = useCallback(
        (result, file) => {
          const newFile = result.fileKey
          let newFileList
        console.log("onFinish", result);
          if (multiple) {
            const filteredFiles = files.filter(
                    stateFile => !shallowEqual(stateFile, file),
                )
            console.log('onFinishFileUpload', files, result.fileKey)

            newFileList = [...filteredFiles, newFile]
          } else {
            newFileList =  [newFile]
          }

          onChange(multiple ? newFileList : newFile)
        },
        [value, onChange, files],
    )

      const childrenElement = isValidElement(Children.only(children))
        ? (Children.only(children) as ReactElement<any>)
        : undefined

      const { getRootProps, getInputProps } = useDropzone({
        ...options,
        accept,
        maxSize,
        minSize,
        multiple,
        onDrop,
      })

      console.log('Files', files)
      return (
        <Labeled
            id={id}
            label={label}
            className={classnames(classes.root, className)}
            source={source}
            resource={resource}
            isRequired={isRequired}
            meta={meta}
            {...sanitizeRestProps(rest)}
        >
            <>
                <div
                    data-testid="dropzone"
                    className={classes.dropZone}
                    {...getRootProps()}
                >
                    <input
                        id={id}
                        {...getInputProps({
                          ...inputProps,
                          ...inputPropsOptions,
                        })}
                    />
                    {placeholder ? (
                        placeholder
                    ) : multiple ? (
                        <p>{translate(labelMultiple)}</p>
                    ) : (
                        <p>{translate(labelSingle)}</p>
                    )}
                </div>
                <FormHelperText>
                    <InputHelperText
                        touched={touched}
                        error={error}
                        helperText={helperText}
                    />
                </FormHelperText>
                {children && (
                    <div className={classes.previews}>
                        {(Array.isArray(files) ? files : [files]).map((file, index) => (
                            <FileInputPreview
                                key={index}
                                file={file}
                                loading={!!file.rawFile}
                                progress={file && file.rawFile ? fileProgress[file.rawFile.path] : 0}
                                onRemove={onRemove(file)}
                                className={classes.removeButton}
                            >
                                {cloneElement(childrenElement, {
                                  record: file && file.rawFile ? file : { src: file },
                                  className: classes.preview,
                                })}
                            </FileInputPreview>
                        ))}
                    </div>
                )}
            </>
        </Labeled>
      )
    }

FileInput.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
}
