import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Avatar from "src/components/Avatar";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      "&:hover": {
        "& $text": {
          textDecoration: 'underline'
        }
      }
    },
    text: {
      marginLeft: '0.5rem',

    },

  },
  { name: 'RaImageField' },
)
const NameWithImageField = ({
                              sourceImage, sourceName, firstLetter,  record = {},
                        }: any) => {
  const classes = useStyles()
  return (<div className={classes.root}>
  { record[sourceImage] || firstLetter ? <Avatar image={record[sourceImage]} name={record[sourceName] || record['title']}/> : null} {record[sourceName] ? <div className={classes.text}> {record[sourceName]}</div> : null}
</div>)
}

NameWithImageField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  firstLetter: PropTypes.bool,
  source: PropTypes.string,
  sourceName: PropTypes.string.isRequired,
  sourceImage: PropTypes.string.isRequired,
}

NameWithImageField.defaultProps = {
  firstLetter: true,
  sourceName: 'name',
  sourceImage: 'image'
};
export default NameWithImageField
