import PropTypes from "prop-types";
import * as React from 'react';
import { useCallback } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
  useNotify,
} from 'ra-core';
import { Button } from 'react-admin'
import { httpClient } from "src/common/providers/dataProvider";
import AvatarField from "src/components/fields/AvatarField";

const DownloadReleaseButton = ({ source,label, labelButton, sourceName, type, record }) =>{
  const notify = useNotify();
  const handleClick = useCallback(event => {
    httpClient(`${process.env.REACT_APP_API_URL || ''}/admin/api/app-release/${(record as any).id}/download/sign?type=${type}`).then((data) => {
      console.log("get data", data)
      const link = data.body as any;
      window.location.href = link as string;
    }).catch(err => {
      notify('ra.notification.http_error', 'warning');
    })

    }, [record]);

  return ((record as any).apkFile ? <Button
    onClick={handleClick}
    label={labelButton}
  >
    {<DownloadIcon/>}
  </Button> : null)
}

DownloadReleaseButton.propTypes = {
  label: PropTypes.string,
  labelButton: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  sourceName: PropTypes.string,
}
export default DownloadReleaseButton

