import React, { FunctionComponent, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import Lightbox from 'react-image-lightbox';
import sanitizeRestProps from '../sanitizeRestProps'
import { FieldProps, InjectedFieldProps, fieldPropTypes } from '../types'
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
      margin: '0px',
      padding: '0px'
    },
    image: {
      maxHeight: '8rem',
      maxWidth: '8rem',
    },
    link: {
      cursor: 'pointer',
      zIndex: 1000
    }
  },
  { name: 'RaImageField' },
)

interface Props extends FieldProps {
  src?: string
  title?: string
  classes?: object,
  maxWidth?: number
}

const S3ImageField: FunctionComponent<Props & InjectedFieldProps> = props => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    record,
    source,
    src = 'path',
    title,
    ...rest
  } = props
  const sourceValue = get(record, source)
  const classes = useStyles(props)
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => {
    setPhotoIndex(0);
    if (Array.isArray(sourceValue)) {
      setImages(sourceValue);
    } else {
      setImages([sourceValue]);
    }
  }, [sourceValue])

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeRestProps(rest)} />
    )
  }
  const getImageSrc = (file) => {
    const srcValue = get(file, src) || file
    if(!srcValue){
      return;
    }
    return `${srcValue.indexOf('blob:') === 0 ? srcValue : (`${process.env.REACT_APP_API_URL || ''}/api/media/show/${srcValue}`)}`
  }
  const titleValue = get(record, title) || title
  return <>
    <ul
      className={classnames(classes.list, className)}
      {...sanitizeRestProps(rest)}
    >
      {(Array.isArray(sourceValue) ? sourceValue : [sourceValue]).map((file, index) => {
        const fileTitleValue = get(file, title) || title
        const srcValue = get(file, src) || file
        console.log("FILEIMG", file)
        return (
          <li key={index}>
            {file?.includes('.zip') ? <a  href={getImageSrc(file)}   className={classnames(classes.link, className)} >
              {fileTitleValue}
            </a> : <a     className={classnames(classes.link, className)} onClick={(e) => {setIsOpen(true); e.preventDefault()}}>
              <img
                alt={fileTitleValue}
                title={fileTitleValue}
                src={getImageSrc(file)}
                style={{...(props.maxWidth ? {maxWidth: props.maxWidth} : {})}}
                className={classes.image}
              />
            </a>}
          </li>
        )
      })}
    </ul>
    {isOpen && (
      <Lightbox
        mainSrc={getImageSrc(images[photoIndex])}
        nextSrc={getImageSrc(images[(photoIndex + 1) % images.length])}
        prevSrc={getImageSrc(images[(photoIndex + images.length - 1) % images.length])}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      />
    )}
  </>

}

// wat? TypeScript looses the displayName if we don't set it explicitly
S3ImageField.displayName = 'ImageField'

S3ImageField.defaultProps = {
  addLabel: true,
}

S3ImageField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
}

export default S3ImageField
