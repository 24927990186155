import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  BooleanInput,
  SimpleForm,
  TextField,
  NumberInput,
  TextInput, ReferenceInput, ReferenceField,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import {S3ImageInput} from 'src/components/inputs/S3FileUpload/S3ImageUpload'
import S3ImageField from 'src/components/fields/S3ImageField'

const WebViewLinkFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$startsL&key||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const WebViewLinkList = (props) => (
  <List
    {...props}
    title="WebView ссылки"
    filters={<WebViewLinkFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет webview ссылок'} description={'Вы можете добавить webview ссылку'} buttonText={'Создать webview ссылку'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
      <S3ImageField source="image" label="Скрин" maxWidth={100}/>
      <TextField source="name" label={'Название'}/>
      <TextField source="key" label={'Ключ'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Сборка: {record?.name || record?.appId}
    </span>
  )
}


const Form = ({...rest}) => {
  const source = rest.source || '';
  return (<>
    <S3ImageInput
      source="image"
      label={'Скрин'}
      uploadOptions={{
        multiple: false
      }}
    >
      <S3ImageField source="src" title="title"/>
    </S3ImageInput>
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <TextInput source="key" label={'Ключ'} variant={'outlined'} fullWidth={true}     validate={required()}/>

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const WebViewLinkEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Edit>
)

export const WebViewLinkCreate = (props) => (
  <Create title="Создать WebView ссылку" {...props}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Create>
)
