import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'

const NotificationTransportFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$contL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const NotificationTransportList = (props) => (
  <List
    {...props}
    title="Уведомление аккаунты"
    filters={<NotificationTransportFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет Аккаунтов для уведомлений'} description={'Вы можете добавить Аккаунт для уведомлений'} buttonText={'Создать аккаунт уведомлений'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
      <TextField source="name" label={'Название'}/>
      <TextField source="type" label={'Тип'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Аккаунт уведомления: {record?.name || record?.appId}
    </span>
  )
}


const Form = ({...rest}) => {
  const source = rest.source || '';
  return (<>
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <SelectInput
      source="type"
      initialValue={'manual'}
      label={'Тип'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'email', name: 'Email' },
        { id: 'slack', name: 'Slack' },
        { id: 'telegram', name: 'Telegram' },
      ]}
      validate={required()}
    />
    <TextInput source="token" label={'Token'} variant={'outlined'} fullWidth={true}     validate={required()}/>

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const NotificationTransportEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Edit>
)

export const NotificationTransportCreate = (props) => (
  <Create title="Создать аккаунт уведомлений" {...props}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Create>
)
