import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput, ReferenceField,
} from 'react-admin'

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import NameWithImageField from "src/components/fields/NameWithImageField";

const UserFiler = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'}/>
    <TextInput source="login||$startsL&name||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const UserList = (props) => (
  <List
    {...props}
    title="Пользователи"
    filters={<UserFiler/>}
    actions={<ListActions/>}
    sort={{ field: 'login', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет пользователей'} description={'Вы можете добавить нового пользователя'} buttonText={'Добавить пользователя'}/>}
  >
    <Datagrid rowClick={'edit'}>
    <NameWithImageField sourceName={'login'} sourceImage={'avatar'} label={'Login'}/>
      <TextField source="id" label={'ID'}/>
      <TextField source="name" label={'Имя'}/>
      <ReferenceField label="Аккаунт" source="account" reference="account">
        <TextField source="name"  />
      </ReferenceField>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Пользователь: {record?.login}
    </span>
  )
}

export const UserEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      {(props.permissions === 'super_admin' || props.permissions === 'admin')  && <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'} validate={required()}
                                                                                                  fullWidth={true} perPage={100}>
        <SelectInput optionText="name"/>
      </ReferenceInput >}
      <TextInput source="login" label={'Login'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="name" label={'Имя'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="setPassword" label={'Новый пароль'} variant={'outlined'} fullWidth={true}/>
      <SelectInput
        source="role"
        variant={'outlined'}
        label={'Роль'}
        fullWidth={true}
        validate={required()}
        choices={props.permissions === 'super_admin' ? [
          { id: 'super_admin', name: 'Супер админ' },
          { id: 'admin', name: 'Админ' },
          { id: 'account_admin', name: 'Админ аккаунт' },
          { id: 'manager', name: 'Менеджер аккаунта' }]: [
          { id: 'admin', name: 'Админ' },
          { id: 'account_admin', name: 'Админ аккаунта' },
          { id: 'manager', name: 'Менеджер аккаунта' }
        ]}
      />

    </SimpleForm>
  </Edit>
)

export const UserCreate = (props) => (
  <Create title="Создать пользователя" {...props}>
    <SimpleForm redirect={'list'}>
      {(props.permissions === 'super_admin' || props.permissions === 'admin')  && <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'} validate={required()}
                                                                                                  fullWidth={true} perPage={100}>
        <SelectInput optionText="name"/>
      </ReferenceInput >}
      <TextInput source="login" label={'Логин'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="name" label={'Имя'} variant={'outlined'} fullWidth={true}  validate={required()}/>
      <TextInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <SelectInput
        source="role"
        variant={'outlined'}
        label={'Роль'}
        fullWidth={true}
        validate={required()}
        choices={props.permissions === 'super_admin' ? [
          { id: 'super_admin', name: 'Супер админ' },
          { id: 'admin', name: 'Админ' },
          { id: 'account_admin', name: 'Админ аккаунт' },
          { id: 'manager', name: 'Менеджер аккаунта' }]: [
          { id: 'admin', name: 'Админ' },
          { id: 'account_admin', name: 'Админ аккаунта' },
          { id: 'manager', name: 'Менеджер аккаунта' }
        ]}
      />

    </SimpleForm>
  </Create>
)
