import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  ArrayInput, SimpleFormIterator,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  BooleanInput,
  ImageField,
  EditButton,
  AutocompleteInput, ReferenceArrayInput, ReferenceField,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'


const LinkRuleFilter = (props) => (
  <Filter {...props}>

    <TextInput source="id" label={'ID'}/>
    <TextInput source="link||$startsL&namingLink||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>
    <ReferenceInput label="Приложение" source="appId" reference="app" variant={'outlined'}
                    fullWidth={true} alwaysOn={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    {(props.permissions === 'super_admin' || props.permissions === 'admin') && <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'}
                    fullWidth={true} alwaysOn={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>}
  </Filter>
)

export const LinkRuleList = (props) => (
  <List
    {...props}
    title="Правила ссылок"
    filters={<LinkRuleFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'No links'} description={'You can add an link'} buttonText={'Create Link'}/>}
  >
    <Datagrid>

      <IdShowButtonField source="id" label={'ID'}/>
      <ReferenceField label="App" source="appId" reference="app">
        <TextField source="name" />
      </ReferenceField>
      {(props.permissions === 'super_admin' || props.permissions === 'admin') &&  <ReferenceField label="Аккаунт" source="accountId" reference="account">
        <TextField source="name" />
      </ReferenceField>}
      <TextField source="link" label={'Ссылка'}/>
      <EditButton label={''}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Правило ссылки: {record?.link}
    </span>
  )
}


const Form = ({permissions, ...rest}) => {
  const source = rest.source || '';
  return (<>
    {(permissions === 'super_admin' || permissions === 'admin') && <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'} validate={required()}
                                                                                   fullWidth={true}>
      <SelectInput optionText="name"/>
    </ReferenceInput>}
    <ReferenceInput label="Приложение" source="appId" reference="app" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL&appId||$contL': searchText} : {}) })}
                    sort={{ field: 'name', order: 'ASC' }}
                    fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name} (${record?.appId})`}/>
    </ReferenceInput>
    <TextInput source="link" label={'Ссылка'} variant={'outlined'} fullWidth={true}  validate={required()}/>
    <TextInput source="evalJs" multiline label={'JS'} variant={'outlined'} fullWidth={true} />
    <TextInput source="description" multiline label={'Комментарий'} variant={'outlined'} fullWidth={true} />

    <BooleanInput source="isRandomShow" multiline label={'isRandomShow'} variant={'outlined'} fullWidth={true} />
    <TextInput source="durationActiveFrom" label={'durationActiveFrom'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="durationActiveTo" label={'durationActiveFrom'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="durationDisabledFrom" label={'durationDisabledFrom'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="durationDisabledTo" label={'durationDisabledTo'} variant={'outlined'} fullWidth={true}/>
    <ArrayInput source="redirectsExists" label={'Есть ли часть url в редиректах'} variant={'outlined'}>
      <SimpleFormIterator>
        <TextInput source="url" label={'Часть url'} variant={'outlined'} fullWidth={true} />
      </SimpleFormIterator>
    </ArrayInput>
  </>)
}
Form.propTypes = {
  permissions: PropTypes.string,
}

Form.defaultProps = {

}

export const LinkRuleEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const LinkRuleCreate = (props) => (
  <Create title="Создать правило ссылки" {...props}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Create>
)
