import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput, ReferenceInput, ReferenceField, AutocompleteInput,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import DownloadReleaseButton from "src/resources/app-releases/components/DownloadButton";

const AppReleaseFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Приложение" source="appId" reference="app" variant={'outlined'}
                    fullWidth={true} alwaysOn={true} resettable={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <TextInput source="name||$startsL&repoUrl||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const AppReleaseList = (props) => (
  <List
    {...props}
    title="Сборки"
    filters={<AppReleaseFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет релизов'} description={'Вы можете добавить релиз'} buttonText={'Создать релиз'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
      <ReferenceField label="Приложение" source="appId" reference="app">
        <TextField source="name"/>
      </ReferenceField>
      <TextField source="version" label={'Версия'}/>
      <TextField source="statusName" label={'Статус'}/>
      <TextField source="typeName" label={'Тип'}/>
      <TextField source="name" label={'Название'}/>
      <DownloadReleaseButton source={'apkFile'} label={''} type={'apk'} labelButton={'APK'}/>
      <DownloadReleaseButton source={'aabFile'} label={''} type={'aab'} labelButton={'AAB'}/>

    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Релиз: {record?.name || record?.version}
    </span>
  )
}


const Form = ({record, ...rest}) => {
  const source = rest.source || '';
  return (<>

    <ReferenceInput label="Приложение" source="appId" reference="app" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL&appId||$contL': searchText} : {}) })}
                    sort={{ field: 'id', order: 'DESC' }}
                    fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name} (${record?.appId})`}/>
    </ReferenceInput>
    <SelectInput
      source="type"
      initialValue={'manual'}
      label={'Тип'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'manual', name: 'Вручную' },
        { id: 'auto', name: 'Авто' },
      ]}
      validate={required()}
    />
    <SelectInput
      source="setStatus"
      initialValue={'created'}
      label={record && record?.status ? 'Установить стаус' : 'Статус'}
      variant={'outlined'} fullWidth={true}
      validate={record && record?.status ? required() : null}
      allowEmpty={record && record?.status ? true : false}
      choices={[
        { id: 'created', name: 'Создан' },
        { id: 'sentToApprove', name: 'sentToApprove' },
        { id: 'published', name: 'published' },
      ]}
    />
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true} />
    <TextInput source="description" label={'Описание'} variant={'outlined'} fullWidth={true}  multiline={true}/>
    <TextInput source="version" label={'Версия'} variant={'outlined'} fullWidth={true}     validate={required()}/>

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const AppReleaseEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const AppReleaseCreate = (props) => (
  <Create title="Создать релиз" {...props}>
    <SimpleForm redirect={'list'} >
      <Form {...props}/>
    </SimpleForm>
  </Create>
)
