import * as React from 'react';
import { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import {Button} from 'react-admin'
const IdShowButtonField: FC<IdShowButtonFieldProps> = ({
                                           basePath = '',

                                           record,
                                           icon = defaultIcon,
                                           ...rest
                                         }) => (
  <Link
    to={`${linkToRecord(basePath, record && record?.id)}/show`}
  >
    {record?.id}
  </Link>
);

const defaultIcon = <ImageEye />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type IdShowButtonFieldProps = Props;

IdShowButtonField.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  record: PropTypes.any,
};

const enhance = shouldUpdate(
  (props: Props, nextProps: Props) =>
    (props.record &&
      nextProps.record &&
      props.record?.id !== nextProps.record?.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
);

export default enhance(IdShowButtonField);
