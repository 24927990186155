import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'

const FirebaseAccountFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$startsL&projectLink||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const FirebaseAccountList = (props) => (
  <List
    {...props}
    title="Firebase акки"
    filters={<FirebaseAccountFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет firebase аккаунтов'} description={'Вы можете добавить Firebase аккаунт'} buttonText={'Создать Firebase аккаунт'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
      <TextField source="name" label={'Название'}/>
      <TextField source="projectLink" label={'URL проекта'}/>
      <TextField source="description" label={'Описание'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Firebase аккаунт: {record?.name || record?.appId}
    </span>
  )
}


const Form = ({...rest}) => {
  const source = rest.source || '';
  return (<>
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <TextInput source="projectLink" label={'URL проекта'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <TextInput source="description" label={'Описание'} variant={'outlined'} fullWidth={true}  multiline={true}  />

    <TextInput source="projectId" label={'projectId'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <TextInput source="apiKey" label={'apiKey'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>
    <TextInput source="projectNumber" label={'projectNumber'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>
    <TextInput source="firebaseUrl" label={'firebaseUrl'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>
    <TextInput source="storageBucket" label={'storageBucket'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>
    <TextInput source="clientId" label={'clientId'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>
    <TextInput source="clientType" label={'clientType'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>

    <TextInput source="senderId" label={'senderId'} variant={'outlined'} fullWidth={true}  multiline={true}   validate={required()}/>
    <TextInput source="setCredentials" label={'Доступы'} variant={'outlined'} fullWidth={true}  multiline={true}  />

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const FirebaseAccountEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Edit>
)

export const FirebaseAccountCreate = (props) => (
  <Create title="Создать Firebase аккаунт" {...props}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Create>
)
