import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  SelectField,
  ReferenceField,
  CheckboxGroupInput,
  AutocompleteInput, ReferenceInput,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import Chip from '@material-ui/core/Chip'
const events = [
  {id: 'appStatus', name: 'Статус публикации'},
  {id: 'appPublished', name: 'Опубликовано'},
  {id: 'appBlocked', name: 'Заблокировано'},
  {id: 'leadEvent', name: 'диплинк'},
  {id: 'report', name: 'Отчет'},
  {id: 'organicSet', name: 'Органика установлена'},
  {id: 'organicDelete', name: 'Органика удалена'},
]


const TextArrayField = ({ record, source }: {record?: any, source: string}) => {
  const array = record[source]
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div/>
  } else {
    return (
      <>
        {array.map(item => <Chip label={events.find( i => i.id === item).name} key={item}/>)}
      </>
    )
  }
}
TextArrayField.defaultProps = { addLabel: true }

const NotificationChannelFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$contL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const NotificationChannelList = (props) => (
  <List
    {...props}
    title="Уведомление каналы"
    filters={<NotificationChannelFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет каналов для уведомлений'} description={'Вы можете добавить канал для уведомлений'} buttonText={'Создать канал уведомлений'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
      <TextField source="name" label={'Название'}/>
      <TextField source="channelId" label={'ID канала'}/>
      <TextArrayField source="events" />
      <ReferenceField label="Аккаунт" source="accountId" reference="account">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Акк" source="transportId" reference="notification-transport">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Канал уведомлений: {record?.name}
    </span>
  )
}


const Form = ({...rest}) => {
  const source = rest.source || '';
  return (<>
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'}
                    fullWidth={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <ReferenceInput label="Аккаунт уведомлений" source="transportId" reference="notification-transport" variant={'outlined'}
                    fullWidth={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <CheckboxGroupInput
      source="events"
      label={'События'}
      variant={'outlined'} fullWidth={true}
      choices={events}
      validate={required()}
    />
    <TextInput source="channelId" label={'ID канала'} variant={'outlined'} fullWidth={true}     validate={required()}/>

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const NotificationChannelEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Edit>
)

export const NotificationChannelCreate = (props) => (
  <Create title="Создать аккаунт уведомлений" {...props}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Create>
)
