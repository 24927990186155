import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  BooleanInput,
  ImageField,
  EditButton,
  AutocompleteInput, ReferenceArrayInput, ReferenceField,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import NamingField from "src/resources/links/components/NamingField";
import {Radio} from "@material-ui/icons";

const LinkFilter = (props) => (
  <Filter {...props}>

    <TextInput source="id" label={'ID'}/>
    <TextInput source="link||$startsL&namingLink||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>
    <ReferenceInput label="Приложение" source="appId" reference="app" variant={'outlined'}
                    fullWidth={true} alwaysOn={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    {(props.permissions === 'super_admin' || props.permissions === 'admin') && <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'}
                    fullWidth={true} alwaysOn={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>}
    <SelectInput
      source="type"
      label={'Type'}
      choices={[
        { id: 'tiktok', name: 'TikTok' },
        { id: 'facebook', name: 'Facebook' },
      ]}
    />
  </Filter>
)

export const LinkList = (props) => (
  <List
    {...props}
    title="Ссылки"
    filters={<LinkFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'No links'} description={'You can add an link'} buttonText={'Create Link'}/>}
  >
    <Datagrid>

      <IdShowButtonField source="id" label={'ID'}/>
      <ReferenceField label="App" source="appId" reference="app">
        <TextField source="name" />
      </ReferenceField>
      {(props.permissions === 'super_admin' || props.permissions === 'admin') &&  <ReferenceField label="Аккаунт" source="accountId" reference="account">
        <TextField source="name" />
      </ReferenceField>}
      <TextField source="typeName" label={'Тип'}/>
      <TextField source="link" label={'Ссылка'}/>
      <TextField source="activityStatusName" label={'Статус'}/>
      <NamingField source="namingLink" label={'Нэйминг'}/>
      <TextField source="rawLink" label={'Сырая ссылка'}/>
      <EditButton label={''}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Ссылка: {record?.link}
    </span>
  )
}


const Form = ({permissions, ...rest}) => {
  const source = rest.source || '';
  return (<>
    {(permissions === 'super_admin' || permissions === 'admin') && <ReferenceInput label="Аккаунт" source="accountId" reference="account" variant={'outlined'}
                                                                                   fullWidth={true}>
      <SelectInput optionText="name"/>
    </ReferenceInput>}
    <ReferenceInput label="Приложение" source="appId" reference="app" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL&appId||$contL': searchText} : {}) })}
                    sort={{ field: 'name', order: 'ASC' }}
                    fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(record) => `${record?.name} (${record?.appId})`}/>
    </ReferenceInput>
    <RadioButtonGroupInput
      source="type"
      label={'Тип'}
      validate={required()}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'tiktok', name: 'TikTok' },
        { id: 'facebook', name: 'Facebook' },
      ]}
    />
    <RadioButtonGroupInput
      source="encodingType"
      label={'Кодирование'}
      validate={required()}
      initialValue={'base64'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'base64', name: 'Обычное' },
        { id: 'sort', name: 'Сокращенное' },
      ]}
    />
    <TextInput source="link" label={'Ссылка'} variant={'outlined'} fullWidth={true}  validate={required()}/>
  </>)
}
Form.propTypes = {
  permissions: PropTypes.string,
}

Form.defaultProps = {

}

export const LinkEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const LinkCreate = (props) => (
  <Create title="Создать ссылку" {...props}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Create>
)
