import React, { useCallback } from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  NumberInput,
  ImageField,
  CheckboxGroupInput,
  ArrayInput,SimpleFormIterator,
  AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput, ReferenceField,
} from 'react-admin'
import { Typography, Box } from '@material-ui/core';
import IdShowButtonField from "src/components/fields/IdShowButtonField";
import S3ImageField from "src/components/fields/S3ImageField";
import { S3ImageInput } from "src/components/inputs/S3FileUpload/S3ImageUpload";
import { useForm, useFormState } from 'react-final-form';
import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import Countries from 'src/utils/countries.json';
const slugify = require('slugify')


const continents = [
  {id: 'AF', label: 'Africa' },
  {id: 'NA', label: 'North America' },
  {id: 'OC', label: 'Oceania' },
  {id: 'AN', label: 'Antarctica' },
  {id: 'AS', label: 'Asia' },
  {id: 'EU', label: 'Europe' },
  {id: 'SA', label: 'South America' }
];
const templateTypes = [
  {id: '1', name: '1' },
  {id: '2', name: '2' },
  {id: '3', name: '3' },
  {id: '4', name: '4' },
];
const colors = [
  {id: 'black', name: 'black' },
  {id: 'black54', name: 'black54' },
  {id: 'black45', name: 'black45' },
  {id: 'black38', name: 'black38' },
  {id: 'black26', name: 'black26' },
  {id: 'black12', name: 'black12' },
  {id: 'white70', name: 'white70' },
  {id: 'white60', name: 'white60' },
  {id: 'white54', name: 'white54' },
  {id: 'white38', name: 'white38' },
  {id: 'white30', name: 'white30' },
  {id: 'white12', name: 'white12' },
  {id: 'blue', name: 'blue' },
  {id: 'blueAccent', name: 'blueAccent' },
  {id: 'blueGrey', name: 'blueGrey' },
  {id: 'lightBlueAccent', name: 'lightBlueAccent' },
  {id: 'lightBlue', name: 'lightBlue' },
  {id: 'yellow', name: 'yellow' },
  {id: 'yellowAccent', name: 'yellowAccent' },
  {id: 'red', name: 'red' },
  {id: 'redAccent', name: 'redAccent' },
  {id: 'green', name: 'green' },
  {id: 'lightGreen', name: 'lightGreen' },
  {id: 'greenAccent', name: 'greenAccent' },
  {id: 'lightGreenAccent', name: 'lightGreenAccent' },
  {id: 'redAccent', name: 'redAccent' },
  {id: 'cyan', name: 'cyan' },
  {id: 'cyanAccent', name: 'cyanAccent' },
  {id: 'orange', name: 'orange' },
  {id: 'orangeAccent', name: 'orangeAccent' },
  {id: 'deepOrange', name: 'deepOrange' },
  {id: 'deepOrangeAccent', name: 'deepOrangeAccent' },
  {id: 'pink', name: 'pink' },
  {id: 'brown', name: 'brown' },

]


const themeColors = [
  {id: 'red', name: 'Красный' },
  {id: 'green', name: 'Зеленый' },
  {id: 'blue', name: 'Синиий' },
  {id: 'orange', name: 'Оранжевый' },

]

const sizes = [
  {id: 50, name: '50' },
  {id: 60, name: '60' },
  {id: 80, name: '80' },
  {id: 90, name: '90' },
  {id: 100, name: '100' },
  {id: 110, name: '110' },
  {id: 120, name: '120' },


]
const loaderTypes = [
  {id: 'spinkkit_RotatingPlain', name: 'spinkkit_RotatingPlain' },
  {id: 'spinkkit_DoubleBounce', name: 'spinkkit_DoubleBounce' },
  {id: 'spinkkit_Wave', name: 'spinkkit_Wave' },
  {id: 'spinkkit_WanderingCubes', name: 'spinkkit_WanderingCubes' },
  {id: 'spinkkit_FadingFour', name: 'spinkkit_FadingFour' },
  {id: 'spinkkit_FadingCube', name: 'spinkkit_FadingCube' },
  {id: 'spinkkit_Pulse', name: 'spinkkit_Pulse' },
  {id: 'spinkkit_ChasingDots', name: 'spinkkit_ChasingDots' },
  {id: 'spinkkit_ThreeBounce', name: 'spinkkit_ThreeBounce' },
  {id: 'spinkkit_Circle', name: 'spinkkit_Circle' },
  {id: 'spinkkit_CubeGrid', name: 'spinkkit_CubeGrid' },
  {id: 'spinkkit_FadingCircle', name: 'spinkkit_FadingCircle' },
  {id: 'spinkkit_RotatingCircle', name: 'spinkkit_RotatingCircle' },
  {id: 'spinkkit_FoldingCube', name: 'spinkkit_FoldingCube' },
  {id: 'spinkkit_DualRing', name: 'spinkkit_DualRing' },
  {id: 'spinkkit_HourGlass', name: 'spinkkit_HourGlass' },
  {id: 'spinkkit_PouringHourGlass', name: 'spinkkit_PouringHourGlass' },
  {id: 'spinkkit_FadingGrid', name: 'spinkkit_FadingGrid' },
  {id: 'spinkkit_Ring', name: 'spinkkit_Ring' },
  {id: 'spinkkit_Ripple', name: 'spinkkit_Ripple' },
  {id: 'spinkkit_SpinningCircle', name: 'spinkkit_SpinningCircle' },
  {id: 'spinkkit_SquareCircle', name: 'spinkkit_SquareCircle' },
  {id: 'loading_indicator_ballPulse', name: 'loading_indicator_ballPulse' },
  {id: 'loading_indicator_ballGridPulse', name: 'loading_indicator_ballGridPulse' },
  {id: 'loading_indicator_ballClipRotate', name: 'loading_indicator_ballClipRotate' },
  {id: 'loading_indicator_squareSpin', name: 'loading_indicator_squareSpin' },
  {id: 'loading_indicator_ballClipRotatePulse', name: 'loading_indicator_ballClipRotatePulse' },
  {id: 'loading_indicator_ballClipRotateMultiple', name: 'loading_indicator_ballClipRotateMultiple' },
  {id: 'loading_indicator_ballPulseRise', name: 'loading_indicator_ballPulseRise' },
  {id: 'loading_indicator_ballRotate', name: 'loading_indicator_ballRotate' },
  {id: 'loading_indicator_cubeTransition', name: 'loading_indicator_cubeTransition' },
  {id: 'loading_indicator_ballZigZag', name: 'loading_indicator_ballZigZag' },
  {id: 'loading_indicator_ballZigZagDeflect', name: 'loading_indicator_ballZigZagDeflect' },
  {id: 'loading_indicator_ballTrianglePath', name: 'loading_indicator_ballTrianglePath' },
  {id: 'loading_indicator_ballScale', name: 'loading_indicator_ballScale' },
  {id: 'loading_indicator_lineScale', name: 'loading_indicator_lineScale' },
  {id: 'loading_indicator_lineScaleParty', name: 'loading_indicator_lineScaleParty' },
  {id: 'loading_indicator_ballScaleMultiple', name: 'loading_indicator_ballScaleMultiple' },
  {id: 'loading_indicator_ballPulseSync', name: 'loading_indicator_ballPulseSync' },
  {id: 'loading_indicator_ballBeat', name: 'loading_indicator_ballBeat' },
  {id: 'loading_indicator_lineScalePulseOut', name: 'loading_indicator_lineScalePulseOut' },
  {id: 'loading_indicator_lineScalePulseOutRapid', name: 'loading_indicator_lineScalePulseOutRapid' },
  {id: 'loading_indicator_ballScaleRipple', name: 'loading_indicator_ballScaleRipple' },
  {id: 'loading_indicator_ballScaleRippleMultiple', name: 'loading_indicator_ballScaleRippleMultiple' },
  {id: 'loading_indicator_ballSpinFadeLoader', name: 'loading_indicator_ballSpinFadeLoader' },
  {id: 'loading_indicator_lineSpinFadeLoader', name: 'loading_indicator_lineSpinFadeLoader' },
  {id: 'loading_indicator_triangleSkewSpi', name: 'loading_indicator_triangleSkewSpi' },
  {id: 'loading_indicator_ballGridBeat', name: 'loading_indicator_ballGridBeat' },
  {id: 'loading_indicator_semiCircleSpin', name: 'loading_indicator_semiCircleSpin' },
  {id: 'loading_indicator_ballRotateChase', name: 'loading_indicator_ballRotateChase' },
  {id: 'loading_indicator_orbit', name: 'loading_indicator_orbit' },
  {id: 'loading_indicator_circleStrokeSpin', name: 'loading_indicator_circleStrokeSpin' },

]
const AppFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$contL" label={'Поиск'} alwaysOn={true} resettable={true}/>
  </Filter>
)

export const AccountAppTemplateList = (props) => (
  <List
    {...props}
    title="Шаблоны приложений"
    filters={<AppFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет шаблонов приложений'} description={'Вы можете добавить шаблон приложений'} buttonText={'Создать шаблон приложение'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
       <TextField source="name" label={'Название'}/>
       </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Шаблон: {record?.name}
    </span>
  )
}


const Form = ({...rest}) => {
  const form = useForm();
  const formData = useFormState();
  const {permissions} = rest;

  if(['admin'].includes(permissions)){
    return (<>

    </>)
  }else if(permissions === 'super_admin'){
    return (<>
      <TextInput source="name" label={'Название шаблона'} variant={'outlined'} fullWidth={true}/>

      <SelectInput

        source="bundleType"
        fullWidth={true} variant={'outlined'}
        label={'Тип'}
        defaultValue={'black'}
        choices={[
          { id: 'white', name: 'Белая' },
          { id: 'whiteWithEncryption', name: 'Белая с шифрованием' },
          { id: 'black', name: 'Черная' },
        ]}
      />



      <BooleanInput source="appSettings.isStub" label={'Заглушка'} helperText={''} variant={'outlined'} fullWidth={true}/>
     <ReferenceArrayInput label={'Компании'} source="accountItems" reference="account"
                           format={users => (users || []).map((user => user && user.id ? user.id : user))}
                           variant={'outlined'} fullWidth={true}>
        <AutocompleteArrayInput source="id" optionText="name" variant={'outlined'}/>
      </ReferenceArrayInput>
      <BooleanInput source="appSettings.pushEnabled" label={'Включить push уведомления'} helperText={''} variant={'outlined'} fullWidth={true}/>



      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.platformType === 'android' && (
          <TextInput source="appSettings.firebaseAndroidAppId" label={'Firebase Идентификатор приложения'} variant={'outlined'} fullWidth={true} validate={formData.pushEnabled ? required() : null}/>
        )}
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.platformType === 'ios' && (
          <TextInput source="appSettings.firebaseIosAppId" label={'Firebase Идентификатор приложения'} variant={'outlined'} fullWidth={true} validate={formData.pushEnabled ? required() : null}/>
        )}
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) =>  (
          <TextInput source="appSettings.oneSignalAppId" label={'OneSignal Идентификатор приложения'} variant={'outlined'} fullWidth={true} validate={formData.pushEnabled ? required() : null}/>
        )}
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.platformType === 'android' && (
          <TextInput source="appSettings.appsFlyerAndroidKey" label={'Appsflyer SDK key'} variant={'outlined'} fullWidth={true} />
        )}
      </FormDataConsumer>


      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.platformType === 'ios' && (
          <TextInput source="appSettings.appsFlyerIosKey" label={'Appsflyer SDK key'} variant={'outlined'} fullWidth={true} />
        )}
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.platformType === 'android' && (
          <TextInput source="appSettings.appoDealAndroidKey" label={'Appodeal app key'} variant={'outlined'} fullWidth={true}/>
        )}
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.platformType === 'ios' && (
          <TextInput source="appSettings.appoDealIosKey" label={'Appodeal app key'} variant={'outlined'} fullWidth={true}/>
        )}
      </FormDataConsumer>



      <TextInput source="appSettings.branchKey" label={'Branch Key'} variant={'outlined'} fullWidth={true}/>

      <SelectInput
        source="appSettings.appoDealShowType"
        fullWidth={true} variant={'outlined'}
        allowEmpty={true}
        label={'Показывать appoDeal баннеры'}
        emptyText={'Не показывать'}
        choices={[
          { id: 'all', name: 'Показывать везде' },
          { id: 'link', name: 'Показывать только тем кто по ссылке' },
        ]}
      />
      <BooleanInput source="appSettings.hasInputClickScript" label={'Есть скрипт открытия клавиатуры'} helperText={''} variant={'outlined'} fullWidth={true}/>
      <BooleanInput source="appSettings.saveLink" label={'Сохранять ссылку (для гемблы)'} helperText={''} variant={'outlined'} fullWidth={true}/>
      <BooleanInput source="appSettings.saveLastLink" label={'Сохранять Последнюю ссылку (для гемблы)'} helperText={''} variant={'outlined'} fullWidth={true}/>
      <BooleanInput source="appSettings.useServerNaming" label={'Использовать серверный нейминг'} helperText={''} variant={'outlined'} fullWidth={true}/>
      <BooleanInput source="appSettings.eventEnabled" label={'Разрешить отправку эвентов на сервер'} helperText={''} variant={'outlined'} fullWidth={true}/>

      <TextInput source="appSettings.setUrl" label={'Установить ссылку на органику'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="appSettings.baseAppUrl" label={'Базовая ссылка приложения'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="appSettings.addParamsString" label={'Доп параметры для ссылки'} variant={'outlined'} fullWidth={true}/>
      <AutocompleteArrayInput  fullWidth={true} variant={'outlined'}  source="appSettings.validLinkGeo" label={'Страны разрешены для залива'} choices={Countries.map(country => ({
        id: country.alpha2.toUpperCase(),
        label: country.name
      }))} optionText={(record) => `(${record?.id}) ${record?.label}`} />

      <AutocompleteArrayInput  fullWidth={true} variant={'outlined'}  source="appSettings.restrictedLinkGeo" label={'Страны запрещены для залива'} choices={Countries.map(country => ({
        id: country.alpha2.toUpperCase(),
        label: country.name
      }))} optionText={(record) => `(${record?.id}) ${record?.label}`} />
      <AutocompleteArrayInput  fullWidth={true} variant={'outlined'}  source="appSettings.validLinkContinents" label={'Контитненты разрещены для залива'} choices={continents} optionText={(record) => `(${record?.id}) ${record?.label}`} />
      <AutocompleteArrayInput  fullWidth={true} variant={'outlined'}  source="appSettings.restrictedLinkContinents" label={'Контитненты запрещены для залива'} choices={continents} optionText={(record) => `(${record?.id}) ${record?.label}`} />


      <BooleanInput source="appSettings.useGeo" label={'Использовать ГЕО для установки ссылки'} helperText={''} variant={'outlined'} fullWidth={true}/>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.appSettings && formData.appSettings.useGeo && (
          <AutocompleteArrayInput  fullWidth={true} variant={'outlined'}  source="appSettings.geo" label={'Страны Органики'} choices={Countries.map(country => ({
            id: country.alpha2.toUpperCase(),
            label: country.name
          }))} optionText={(record) => `(${record?.id}) ${record?.label}`} />
        )}
      </FormDataConsumer>

      <CheckboxGroupInput source="appSettings.trackers" label={'Трэкеры'} choices={[
        { id: 'facebook', name: 'Facebook' },
        { id: 'appsflyer', name: 'AppsFlyer' },
     //   { id: 'kochava', name: 'Kochava' },
      //     { id: 'branch', name: 'Branch' },
        { id: 'adjust', name: 'Adjust' },
      ]} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.appSettings?.trackers?.includes('adjust') && (
          <TextInput source="appSettings.adjustKey" label={'Adjust Key'} variant={'outlined'} fullWidth={true}   />
        )}
      </FormDataConsumer>
      <CheckboxGroupInput source="appSettings.integrations" label={'Интеграции'} choices={[
        { id: 'applink', name: 'Applink Network' },
      ]} />

      <ArrayInput source="appSettings.contentRedirectUrlRules" label={'Вхождения url для открытия контента прилы'} variant={'outlined'}  >
        <SimpleFormIterator>
          <TextInput source="url" label={'Вхождение подстроки в адрес'} variant={'outlined'} fullWidth={true}/>
        </SimpleFormIterator>
      </ArrayInput>


      <AutocompleteInput  fullWidth={true} variant={'outlined'}  source="appSettings.certCountry" label={'Сертификат - Страна (C)'} choices={Countries.map(country => ({
        id: country.alpha2.toUpperCase(),
        label: country.name
      }))} optionText={(record) => `(${record?.id}) ${record?.label}`} />
      <TextInput source="appSettings.certProvince" label={'Сертификат - Провинция/штат (S)'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="appSettings.certCity" label={'Сертификат - Город (L)'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="appSettings.certOrg" label={'Сертификат - Организация (O)'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="appSettings.certOrgUnit" label={'Сертификат - Подразделение организции (OU)'} variant={'outlined'} fullWidth={true}/>
      <TextInput source="appSettings.certName" label={'Сертификат - Название (CN)'} variant={'outlined'} fullWidth={true}/>

      <SelectInput
        source="appSettings.template.loader.color"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет лоадера'}
        choices={colors}

      />
      <SelectInput
        source="appSettings.template.loader.bgColor"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет фона лоадера'}
        choices={colors}

      />
      <SelectInput
        source="appSettings.template.loader.type"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}

        label={'Тип лоадера'}
        choices={loaderTypes}
      />
      <SelectInput
        source="appSettings.template.loader.size"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}

        label={'Размер лоадера'}
        choices={sizes}
      />

      <SelectInput
        source="appSettings.template.theme.colorBackground"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет фона'}
        choices={colors}

      />

      <SelectInput
        source="appSettings.template.theme.colorPrimary"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет первичный'}
        choices={colors}

      />
      <SelectInput
        source="appSettings.template.theme.colorSecondary"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет вторичный'}
        choices={colors}

      />
      <SelectInput
        source="appSettings.template.theme.colorPrimaryVariant"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет первичный доп (colorSecondaryVariant)'}
        choices={colors}

      />
      <SelectInput
        source="appSettings.template.theme.colorSecondaryVariant"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет вторичный доп (colorSecondaryVariant)'}
        choices={colors}

      />
      <SelectInput
        source="appSettings.template.theme.colorSurface"
        fullWidth={true} variant={'outlined'}
        allowEmpty={false}
        label={'Цвет Surface (colorSurface)'}
        choices={colors}

      />
      <BooleanInput source={'isCheckStoreBotEnabled'} label={'Проверка ботом статуса публикации'} defaultValue={true}/>

      <SelectInput
        source="appSettings.template.type"
        fullWidth={true} variant={'outlined'}
        allowEmpty={true}
        tip={'Тип вида  приложения. Только для сборок с type в названии'}
        label={'Шаблон приложения (Необязательно)'}
        choices={templateTypes}
      />

      <BooleanInput source="appSettings.collectHTML" label={'Собирать HTML'} helperText={''} variant={'outlined'} fullWidth={true}/>

      <BooleanInput source="appSettings.useDeepLinkUrl" label={'Использовать Доп диплинк'} helperText={''} variant={'outlined'} fullWidth={true}/>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.appSettings && formData.appSettings.useDeepLinkUrl && (
          <TextInput source="appSettings.setDeepLinkUrl" label={'Доп диплинк - ссылка'} variant={'outlined'} fullWidth={true} />

        )}
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.appSettings && formData.appSettings.useDeepLinkUrl && (
          <NumberInput source="appSettings.setDeepLinkInstall" label={'Доп диплинк каждый N инсталлов'} variant={'outlined'} fullWidth={true} />

        )}
      </FormDataConsumer>
      <ArrayInput source="appSettings.additionalGeoUrls" label={'Доп ссылки на органику по ГЕО'} variant={'outlined'}  >
        <SimpleFormIterator>
          <AutocompleteArrayInput  fullWidth={true} variant={'outlined'}  source="geo" label={'Страны'} choices={Countries.map(country => ({
            id: country.alpha2.toUpperCase(),
            label: country.name
          }))} optionText={(record) => `(${record?.id}) ${record?.label}`} />
          <TextInput source="url" label={'Ссылку на органику'} variant={'outlined'} fullWidth={true}/>
        </SimpleFormIterator>
      </ArrayInput>
    </>)
  }else{
    return (<></>)
  }




}
Form.propTypes = {

}

Form.defaultProps = {

}

export const AccountAppTemplateEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const AccountAppTemplateCreate = (props) => {

  return(
  <Create title="Создать шаблон приложение" {...props}>
    <SimpleForm redirect={'list'} >
      <Form {...props}/>
    </SimpleForm>
  </Create>
)}
