import React from 'react'
import PropTypes from 'prop-types'

const NamingField = ({ record = {},
                       }: any) => {
  console.log("RecordType", record)
  const getDeeplinkPrefix = () => {
    if(!record?.app){
      return '';
    }
    if(record?.app.deepLinkPrefix){
      return record?.app.deepLinkPrefix;
    }

    if(record?.app.appSettings.deepLinkPrefix){
      return record?.app.appSettings.deepLinkPrefix;
    }
  }
  return (<> {record?.type === 'facebook' ? `${getDeeplinkPrefix()}${record?.namingLink}` : record?.namingLink}
  </>)
}

NamingField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}

NamingField.defaultProps = {

};
export default NamingField
