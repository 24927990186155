import { stringify } from "querystring";
import React from 'react'
import { Admin, Resource } from 'react-admin'
import Layout from "src/common/layout/Layout";
import { AuthProvider } from "src/common/providers/AuthProvider";
import { dataProvider } from "src/common/providers/dataProvider";
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import { history } from "src/history";
import { AccountCreate, AccountEdit, AccountList } from "src/resources/accounts";
import { AppBundleCreate, AppBundleEdit, AppBundleList } from "src/resources/app-bundles";
import { AppReleaseCreate, AppReleaseEdit, AppReleaseList } from "src/resources/app-releases";

import { WebViewLinkCreate, WebViewLinkEdit, WebViewLinkList } from "src/resources/webview-links";
import { AppCreate, AppEdit, AppList } from "src/resources/apps";
import { FirebaseAccountCreate, FirebaseAccountEdit, FirebaseAccountList } from "src/resources/firebase-account";

import { LinkCreate, LinkEdit, LinkList } from "src/resources/links";
import { PushCreate, PushEdit, PushList } from "src/resources/push";
import { UserCreate, UserEdit, UserList } from "src/resources/users";
import customSagas from './sagas'
import './index.css'

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import StayPrimaryPortraitIcon from '@material-ui/icons/StayPrimaryPortrait';
import LinkIcon from '@material-ui/icons/Link';
import BusinessIcon from '@material-ui/icons/Business';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import AppsIcon from '@material-ui/icons/Apps';
import AddAlertIcon from '@material-ui/icons/AddAlert';

import { theme } from 'src/style'
import {LinkRuleCreate, LinkRuleEdit, LinkRuleList} from "./resources/link-rules";
import {
  NotificationChannelCreate,
  NotificationChannelEdit,
  NotificationChannelList
} from 'src/resources/notification-channel'
import {
  NotificationTransportCreate,
  NotificationTransportEdit,
  NotificationTransportList
} from 'src/resources/notification-transport'
import {
  AccountAppTemplateCreate,
  AccountAppTemplateEdit,
  AccountAppTemplateList
} from "src/resources/account-app-template";

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
  return (
      <Admin
          history={history()}
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={AuthProvider}
          customSagas={customSagas}
          layout={Layout}
      >
        {permissions => [

        ...( permissions === 'super_admin' ? [
          <Resource
            path={'app'}
            name="app"
            list={AppList}
            create={AppCreate}
            edit={AppEdit}
            icon={StayPrimaryPortraitIcon}
            options={ { label: 'Приложения',    items: [
                {
                  name: 'Приложения',
                  to: {
                    pathname: '/app',
                    search: stringify({
                      page: 1,
                      perPage: 50,
                      sort: 'id',
                      order: 'DESC',
                    }),
                  }
                },
                {
                  name: 'Релизы',
                  to: {
                    pathname: '/app-release',
                    search: stringify({
                      page: 1,
                      perPage: 50,
                      sort: 'id',
                      order: 'DESC',
                    }),
                  }
                },
                {
                  name: 'Сборки',
                  to: {
                    pathname: '/app-bundle',
                    search: stringify({
                      page: 1,
                      perPage: 50,
                      sort: 'id',
                      order: 'DESC',
                    }),
                  }
                },
                {
                  name: 'Firebase акки',
                  to: {
                    pathname: '/firebase-account',
                    search: stringify({
                      page: 1,
                      perPage: 50,
                      sort: 'id',
                      order: 'DESC',
                    }),
                  }
                },

              ] }}
          />,
          <Resource
            path={'app-bundle'}
            name="app-bundle"
            list={AppBundleList}
            create={AppBundleCreate}
            edit={AppBundleEdit}
            icon={LaptopMacIcon}
            options={{ label: 'Сборки', hidden: true }}
          />,
          <Resource
            path={'firebase-account'}
            name="firebase-account"
            list={FirebaseAccountList}
            create={FirebaseAccountCreate}
            edit={FirebaseAccountEdit}
            icon={WhatshotIcon}
            options={{ label: 'Firebase акки', hidden: true }}
          />,
          <Resource
            path={'app-release'}
            name="app-release"
            list={AppReleaseList}
            create={AppReleaseCreate}
            edit={AppReleaseEdit}
            icon={AppsIcon}
            options={{ label: 'Релизы', hidden: true }}
          />,
          <Resource
            path={'webview-link'}
            name="webview-link"
            list={WebViewLinkList}
            create={WebViewLinkCreate}
            edit={WebViewLinkEdit}
            icon={LaptopMacIcon}
            options={{ label: 'WebView ссылки', hidden: true }}
          />,
          <Resource
            path={'account-app-template'}
            name="account-app-template"
            list={AccountAppTemplateList}
            create={AccountAppTemplateCreate}
            edit={AccountAppTemplateEdit}
            icon={AppsIcon}
            options={{ label: 'Шаблоны приложений', hidden: false }}
          />



        ]: []),

          ['manager'].includes(permissions) ?
            <Resource
              path={'app'}
              name="app"
              list={AppList}
              icon={StayPrimaryPortraitIcon}
              options={{ label: 'Приложения', hidden: false }}
            />
            : null,
          ['account_admin', 'admin'].includes(permissions) ?
            <Resource
              path={'app'}
              name="app"
              list={AppList}
              edit={AppEdit}
              icon={StayPrimaryPortraitIcon}
              options={{ label: 'Приложения', hidden: false }}
            />
            : null,
          <Resource
            path={'link'}
            name="link"
            list={LinkList}
            create={LinkCreate}
            edit={LinkEdit}
            icon={LinkIcon}
            options={{ label: 'Ссылки', hidden: false }}
          />,
          <Resource
            path={'push'}
            name="push"
            list={PushList}
            create={PushCreate}
            edit={PushEdit}
            icon={NotificationsIcon}
            options={{ label: 'Push уведомления', hidden: false }}
          />,
          permissions === 'super_admin'  ?
            <Resource
              path={'link-rule'}
              name="link-rule"
              list={LinkRuleList}
              create={LinkRuleCreate}
              edit={LinkRuleEdit}
              icon={LinkIcon}
              options={{ label: 'Правила ссылок', hidden: false }}
            /> : null,
          permissions === 'super_admin'  ?
            <Resource
              path={'account'}
              name="account"
              list={AccountList}
              create={AccountCreate}
              edit={AccountEdit}
              icon={BusinessIcon}
              options={{ label: 'Аккаунты', hidden: false }}
            /> : <Resource
              path={'account'}
              name="account"
            />,
          permissions === 'super_admin' ?
            <Resource
              path={'user'}
              name="user"
              list={UserList}
              create={UserCreate}
              edit={UserEdit}
              icon={EmojiPeopleIcon}
              options={{ label: 'Пользователи', hidden: false }}

            /> : null,
          permissions === 'super_admin' ?
            <Resource
              path={'notification-channel'}
              name="notification-channel"
              list={NotificationChannelList}
              create={NotificationChannelCreate}
              edit={NotificationChannelEdit}
              icon={AddAlertIcon}
              options={{ label: 'Каналы уведомлений', hidden: false }}
            /> : null,

          permissions === 'super_admin' ?
            <Resource
              path={'notification-transport'}
              name="notification-transport"
              list={NotificationTransportList}
              create={NotificationTransportCreate}
              edit={NotificationTransportEdit}
              icon={AddAlertIcon}
              options={{ label: 'Аккаунты уведомлений', hidden: false }}
            /> : null,
        ]}





      </Admin>
  )
}

export default App
