import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  BooleanInput,
  SimpleForm,
  TextField,
  NumberInput,
  TextInput, ReferenceInput, ReferenceField,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import {S3ImageInput} from 'src/components/inputs/S3FileUpload/S3ImageUpload'
import S3ImageField from 'src/components/fields/S3ImageField'

const AppBundleFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$startsL&repoUrl||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>

  </Filter>
)

export const AppBundleList = (props) => (
  <List
    {...props}
    title="Сборки"
    filters={<AppBundleFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет сборок'} description={'Вы можете добавить сборку'} buttonText={'Создать сборку'}/>}
  >
    <Datagrid>
      <IdShowButtonField source="id" label={'ID'}/>
      <S3ImageField source="screenshot" label="Иконка" maxWidth={100}/>
      <TextField source="name" label={'Название'}/>
      <TextField source="repoUrl" label={'Репозиторий'}/>
      <TextField source="repoBranch" label={'Ветка'}/>
      <TextField source="description" label={'Описание'}/>
      <ReferenceField label="Firebase Акк" source="firebaseAccountId" reference="firebase-account">
        <TextField source="name"/>
      </ReferenceField>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Сборка: {record?.name || record?.appId}
    </span>
  )
}


const Form = ({...rest}) => {
  const source = rest.source || '';
  return (<>
    <S3ImageInput
      source="screenshot"
      label={'Скрин'}
      uploadOptions={{
        multiple: false
      }}
    >
      <S3ImageField source="src" title="title"/>
    </S3ImageInput>
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <ReferenceInput label="Firebase Аккаунт" source="firebaseAccountId" reference="firebase-account" variant={'outlined'}
                    fullWidth={true} perPage={100}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
    <TextInput source="repoUrl" label={'Репозиторий'} variant={'outlined'} fullWidth={true}     validate={required()}/>
    <TextInput source="repoBranch" label={'Ветка в репозитории'} variant={'outlined'} initialValue={'master'} fullWidth={true}     validate={required()}/>
    <TextInput source="description" label={'Описание'} variant={'outlined'} fullWidth={true}  multiline={true}  />
    <NumberInput source="minFlutterVersion" label={'Мин версия flutter'} variant={'outlined'} fullWidth={true}  />
    <NumberInput source="maxFlutterVersion" label={'Макс версия flutter'} variant={'outlined'} fullWidth={true}  />
    <BooleanInput source="isNullSafety" label={'NullSafety'} variant={'outlined'} fullWidth={true}  />
    <BooleanInput source="skipStringsEncryption" label={'Не шифровать строки'} helperText={''} variant={'outlined'} fullWidth={true}/>
  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const AppBundleEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Edit>
)

export const AppBundleCreate = (props) => (
  <Create title="Создать сборку" {...props}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Create>
)
