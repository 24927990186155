import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput
} from "react-admin";

import ModalForm from 'src/components/ModalForm'

export const ApplyAppTemplateModal = (props) => {
  const {record, form} = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    console.log("SubmitData", data)
    const appTemplate =  await dataProvider.getOne('account-app-template', { id: data.appTemplateId });
    console.log("appTemplate1", appTemplate)
    const keys = Object.keys(appTemplate.data.appSettings)
    for(const key of keys){
      const value = appTemplate.data.appSettings[key]
      if(value){
        form.change(`appSettings.${key}`, value)
      }
    }
  };

  return <ModalForm
    {...props}
    title={`Приминение шаблона`}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <ReferenceInput
      source="appTemplateId"
      reference="account-app-template"
      variant={'outlined'}
      label={'Шаблон'}
      fullWidth
      sort={{field: 'name', order: 'ASC'}}
    >
      <SelectInput source="name"/>
    </ReferenceInput>
  </ModalForm>
}
