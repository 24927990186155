import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput, ReferenceArrayInput,
} from 'react-admin'

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'

const AccountFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'}/>
    <TextInput source="name||$startsL" label={'Поиск'} alwaysOn={true} resettable={true}/>
  </Filter>
)

export const AccountList = (props) => (
  <List
    {...props}
    title="Аккаунты"
    filters={<AccountFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'name', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет аккаунтов'} description={'Вы можете добавить аккаунт'} buttonText={'Создать аккаунт'}/>}
  >

    <Datagrid rowClick={'edit'}>
      <TextField source="id" label={'ID'}/>
      <TextField source="name" label={'Название'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Account: {record?.name}
    </span>
  )
}


const Form = ({...rest}) => {
  const source = rest.source || '';
  return (<>
    <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth={true}  validate={required()}/>

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const AccountEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Edit>
)

export const AccountCreate = (props) => (
  <Create title="Создать аккаунт" {...props}>
    <SimpleForm redirect={'list'}>
      <Form />
    </SimpleForm>
  </Create>
)
