import React, { FunctionComponent, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from '../sanitizeRestProps'
import { FieldProps, InjectedFieldProps, fieldPropTypes } from '../types'
import 'react-image-lightbox/style.css';
import {format} from 'date-fns'


const AppOrganicField = (props) => {
  const {
    className,
   record,
    ...rest
  } = props
if(!record['appSettings']){
  return <></>
}
    return  (
      record['appSettings']['firstSetUrlSetAt'] || record['appSettings']['setUrl'] ? <Typography
        component="span"
        variant="body2"
        color={'error'}
      >
        {record['appSettings']['firstSetUrlSetAt'] ? ` c ${format(new Date(record['appSettings']['firstSetUrlSetAt']), 'dd.MM.yyyy')}` : `Есть`}
      </Typography> : <></>
    )
  }


export default AppOrganicField
